<template>
  <div class="starter-page">
    <CarouselSection class="carousel-section" />
    
    <section class="main-content">

      
      <div class="container">

        <div class="webinar-announcement">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title text-primary"><strong>Nuevo Webinar:</strong> Adaptabilidad microbiana al medioambiente de producción alimentaria.</h3>
              <p class="card-text">
                Únase a nosotros el <strong>28 de marzo</strong> para un webinar exclusivo donde veremos las últimas tendencias en seguridad alimentaria. <strong>Cupos limitados.</strong>
              </p>
    
              <a href="https://us06web.zoom.us/webinar/register/WN_U9YU5GGERn6uYLoABwBsGA" class="btn btn-primary" style="font-size: 2em;">Regístrate aquí</a>
            </div>
          </div>
        </div>


        <div class="intro-text" hidden>
          <p class="text-primary">
            <b>Más del 80 % de los productos elaborados que presentan desvíos en indicadores biológicos
            y/o microorganismos patógenos nocivos para el consumidor, provienen del ambiente en el que se producen
            asesoramos para lograr una producción en un ambiente bajo control e higiene.</b>
          </p>
        </div>

        <div class="services-section">
          <h2 class="section-title">Servicios</h2>
          <Tabs centered type="neutral" class="services-tabs" tab-content-classes="service-content">
            <tab-pane>
              <span slot="label">
                <i class="now-ui-icons business_bulb-63"></i> Auditorías
              </span>
              <p class="paragraph">
                Nuestra evaluación y diagnóstico de inocuidad alimentaria es el primer paso hacia la garantía de la seguridad de tus productos. Nuestro equipo de expertos llevará a cabo una revisión exhaustiva de tus procesos y operaciones para identificar posibles riesgos y áreas de mejora.
              </p>
            </tab-pane>

            <tab-pane>
              <span slot="label">
                <i class="now-ui-icons education_atom"></i> Capacitación
              </span>
              <p class="paragraph">
                Cuando hablamos de Inocuidad Alimentaria, en general hablamos de alimentos libres de patógenos para el consumidor, o en aquellos microrganismos que potencialmente puedan causar pérdida de la calidad, lograr su concentración en el producto dentro de un margen de aceptabilidad reglamentario o normativo durante su vida útil, basado en un profundo y real análisis de riesgo . Entendemos que establecer una “Cultura de trabajo” es posible <strong>si la primera línea de operarios en producción están capacitados, motivados e involucrados con su tarea</strong>, logrando excelentes estándares de producción responsable.
              </p>
            </tab-pane>

            <tab-pane>
              <span slot="label">
                <i class="now-ui-icons location_map-big"></i> Asesorías
              </span>
              <p class="paragraph">
                Los programas de Seguridad Alimentaria, control y gestión de peligros biológicos son fundamentales para asegurar la inocuidad alimentaria en tu empresa. En <strong>JL Consultorías</strong>, ofrecemos mediante trabajo en equipo , el diagnóstico , apoyo , capacitación y planes que se adapten a tus necesidades productivas y metas económicas.
              </p>
            </tab-pane>

            <tab-pane>
              <span slot="label">
                <i class="now-ui-icons ui-2_settings-90"></i> Diagnóstico
              </span>
              <p class="paragraph">
                Ofrecemos mediante apoyo analítico, un diagnóstico de situación real y planes de trabajo que permitan erradicar la presencia de microorganismos “problema” en el ambiente de elaboración de alimentos. <strong>Capacitar de forma remota o presencial, al personal de Calidad y Seguridad alimentaria</strong>, de los atributos moleculares, estructurales, metabólicos y de resistencia, que poseen los microorganismos por lo cual, a pesar del cumplimiento de estándares de limpieza y sanitizado, fallamos en el control de los mismos.
              </p>
            </tab-pane>
          </Tabs>
        </div>



        
        <section class="bio">
        <div class="section section-team text-center">
        <div class="container">
          <div class="team">
            <div class="row">
              <div class="col-md-12">
                <div style="text-align: -webkit-center;" class="team-player ">
                  <div class="profile-image">
                    <img src="img/julio3.jpg" style="height: 200px;" alt="Thumbnail Image" class="rounded-circle img-fluid img-raised" />
                  </div>
                  <h4 class="title">Julio C. Lamela</h4>
                  <p class=" text-primary">Microbiólogo MD</p>
                  <p class="description text-primary">
                    Experto microbiólogo en la evaluación de peligros biológicos de diferentes matrices alimentarias e
                    insumos en equipos HACCP.
                    Amplia experiencia en procesos productivos por más de 35 años.
                  </p>
                  <p class="description text-primary">
                    <b>Doctor en Medicina</b> – Universidad de la República O. del Uruguay ( UDELAR ) <br>
                    <b>Especialista en Microbiología</b> – Instituto de Higiene - Facultad de Medicina <br>
                    <b>Posgrado</b> - Universidad de la República O. del Uruguay <br>
                    <b>Especialista en Seguridad Alimentaria </b>- UNIT <br>
                    <b>Responsable en Gestión de Riesgos </b> - LATU <br>
                    <b>Consultor Asesor</b> - Microbiología industrial <br>

                  </p>

                  <p class="description">jlamela005@gmail.com</p>
                  <p class="description">+598 98 300 386</p>
                  <div clas="row" style="display: inline-flex;">
                    <a href="https://wa.me/+59898300386" class="socialbtn btn btn-primary btn-icon btn-round"><i
                        class="fab fa-whatsapp"></i></a>
                    <a href="https://www.linkedin.com/in/julio-lamela-94743647/"
                      class="socialbtn btn btn-primary btn-icon btn-round"><i class="fab fa-linkedin"></i></a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
        <section class="work-together">
          <h2 class="section-title">Trabajemos juntos</h2>
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-sm-12 col-xl-6 text-center">
                <img 
                  src="/img/food-safety-logo.png"
                  alt="Food Safety Logo"
                  class="food-safety-logo"
                />
              </div>
              <div class="col-sm-12 col-xl-6">
                <p class="text-primary">
                  <b>Agenda una reunión de 30 minutos para conocer acerca de tu situación y poder brindarte una propuesta de
                    valor que garantice la satisfacción de tu negocio en términos de Seguridad Alimentaria</b>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section class="calendar-section">
          <h2 class="section-title" id="title">Agenda una reunión</h2>
          <vue-calendly url="https://calendly.com/germanlamela98/30min" height="900" />
        </section>

        <section class="participations">
          <h2 class="section-title" id="title">Participaciones</h2>
          <Card>
            <div class="linkedin-grid">
              <LinkedinCard
                v-for="(participation, index) in participations"
                :key="index"
                :imageSrc="participation.imageSrc"
                :title="participation.title"
                :desc="participation.desc"
              />
            </div>
          </Card>
        </section>

       
      </div>
    </section>
  </div>
</template>

<script>
import CarouselSection from './components/CarouselSection.vue';
import { Card, Tabs, TabPane } from '@/components';
import LinkedinCard from '../components/LinkedinCard.vue';
import OptimizedImage from './components/OptimizedImage.vue';

export default {
  name: 'starter',
  components: {
    CarouselSection,
    Card,
    Tabs,
    TabPane,
    LinkedinCard,
    
  },
  data() {
    return {
      participations: [
        {
          imageSrc: "img/1609283167775.jpg",
          title: "BARCELONA 201, UAB",
          desc: "Workshop de metodos rápidos y automatización en microbiologia alimentaria. Ponente."
        },
        {
          imageSrc: "https://indufarma.com.uy/wp-content/uploads/2020/10/Indufarma-SETIEMBRE.jpg",
          title: "Indufarma",
          desc: "Una visión de la contaminación biológica de los alimentos con el foco puesto en el ambiente."
        },
        {
          imageSrc: "https://i.postimg.cc/pdNyYwgW/1559347218683.jpg",
          title: "Hotel Estancia San Pedro del Timote",
          desc: "Inocuidad y atributos microbianos favorecedores de la contaminación cruzada."
        },
        {
          imageSrc: "img/176f2acf-7ea2-4e4b-b954-17c4e839760c.jpg",
          title: "Workshop MRAMA - DYCFung",
          desc: "Métodos rápidos y automatización en Microbiología de Alimentos: Detección y cuantificación."
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.starter-page {
  min-height: 100vh;
  background-color: #151515;
  color: #ffffff;

  .carousel-section {
    margin-bottom: 3rem;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
  }

  .main-content {
    padding: 2rem 0;
    
    @media (min-width: 768px) {
      padding: 4rem 0;
    }

    .container {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  .intro-text {
    max-width: 800px;
    margin: 0 auto 3rem;
    padding: 0 1rem;
    text-align: center;
    
    .text-primary {
      color: #ffffff !important;
      font-size: 1.2rem;
      line-height: 1.6;
    }
  }

  .section-title {
    margin: 2rem 0;
    color: #ffffff;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    
    @media (min-width: 768px) {
      font-size: 2.5rem;
      margin: 3rem 0;
    }
  }

  .services-tabs {
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    
    .service-content {
      padding: 2rem;
      color: #ffffff;
      
      @media (min-width: 768px) {
        padding: 3rem;
      }
    }

    .paragraph {
      color: #ffffff;
      font-size: 1.1rem;
      line-height: 1.6;
    }
  }

  .food-safety-logo {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
    object-fit: contain;
    padding: 1rem;
    
    @media (max-width: 767px) {
    max-width: 280px; // Smaller on mobile
    padding: 0.75rem;
  }
  
    @media (min-width: 768px) {
      padding: 2rem;
      max-width: 500px;
    }
  }

  .linkedin-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    padding: 2rem;
    
    > * {
      height: 100%;
    }
    
    @media (min-width: 768px) {
      padding: 3rem;
      grid-template-columns: repeat(2, 1fr);
    }
    
    @media (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .calendar-section {
    margin: 4rem 0;
  }

  .inforow {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 0;
    
    @media (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .work-together {
    padding: 2rem 0;
    
    .food-safety-logo {
      width: auto;
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
      padding: 1rem;
      object-fit: contain;

      @media (max-width: 767px) {
        max-width: 200px; // Smaller on mobile
        padding: 0.75rem;
      }
      
      @media (min-width: 768px) {
        max-width: 80%;
      }
    }

    .row {
      margin: 2rem 0;
    }

    .text-primary {
      padding: 1rem;
      text-align: center;
      
      @media (min-width: 768px) {
        text-align: left;
        padding: 2rem;
      }
    }
  }

  .bio > .section {
    background: #151515!important;
  }

  .bio {
    padding: 2rem 0;
    
    .food-safety-logo {
      width: auto;
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
      padding: 1rem;
      object-fit: contain;

      @media (max-width: 767px) {
        max-width: 200px; // Smaller on mobile
        padding: 0.75rem;
      }
      
      @media (min-width: 768px) {
        max-width: 80%;
      }
    }

    .row {
      margin: 2rem 0;
    }

    .text-primary {
      padding: 1rem;
      text-align: center;
      
      @media (min-width: 768px) {
        text-align: center;
        padding: 2rem;
      }
    }
  }
}
</style>
